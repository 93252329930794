import styled from 'styled-components';

export const Container = styled.div`

  @media only screen and (max-width: 1000px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0rem;

  p {
    font-size: 1.2rem;
    color: white;
  }

  em {
    font-size: 1rem;
    color: white;
  }
`;

export const InputContainer = styled.div`
  padding: 0 35%;
`;

export const MobileContainer = styled(Container)`
  margin: 0;
  padding: 1% 3%;
`;

export const ImageContainer = styled.div`
    width: fit-content
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
    
`; 

export const Image = styled.img`
    width: 100%;
    height: auto;
    margin-top: 5rem;

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
        /* For portrait layouts only */
        margin-top: 2rem;
        width: 100%;
        height: auto;
      }
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        /* For landscape layouts only */
        margin-top: 2rem;
        width: 100%;
        height: auto;
    }
`; 


export const ImageObstacles = styled.img`
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    /* For landscape layouts only */
    position: absolute;
    left: 60%;
    top: 5rem;
    width: 30%;
    height: auto;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    margin-top: 1rem;
    margin-left: 10rem;
    width: 60%;
    height: auto;
  }
  margin-top: 1rem;
  margin-left: 11rem;
  width: 30%;
  height: auto;
   
`; 

export const ButtonContainer = styled.div`
    margin-top: 2rem; 
`; 

export const SingleButtonContainer = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`; 

export const Button = styled.button`
    vertical-align: top;
    color: white;
    margin-left: 3rem;
    min-width: 120px; 
    height: 70px;
    font-size: 20px;
    font-family: 'ForsvarsmaktenSans-Stencil';
    border: 2px solid white;
    border-radius: 10px;
    background-color: #717070;
    &:hover{
        cursor: pointer;
        color: #717070;
        background-color: white;
    }
`;

export const ButtonSub = styled(Button)`
    margin-left: 0px;
`;

export const ButtonStart = styled(Button)`
  border: 2px solid #ffef00;

  &:hover{
      cursor: pointer;
      color: white;
      background-color: #ffef00;
  }
`;

export const MobileButtonStart = styled(ButtonStart)`
  margin-left: 0;
`;

export const ButtonCTA = styled(ButtonStart)`
  height: 70px;
  padding: 0 20px;
  font-size: 22px;
  margin: 0;
`;

export const Select = styled.select`
    -webkit-appearance: none;
    background-color: transparent;
    background-image: url(assets/images/select.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 40px;
    vertical-align: top;
    padding-left: 1.5rem;
    width: 120px; 
    height: 70px;
    font-family: 'ForsvarsmaktenSans-Stencil';
    font-size: 20px;
    color: white;
    background-color: #717070;
    border: 2px solid #ffef00;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
    }

    &:focus {
      outline: none;
    }
`; 

export const Title = styled.h1`
    @media only screen and (max-width: 1000px) {
      font-size: 30px;
      margin-top: 0rem;
    }
    -webkit-appearance: none;
    color: white;
    font-size: 55px;
    margin-bottom: 0rem;
`; 

export const SubTitle = styled.h2`
    @media only screen and (max-width: 1000px) {
      font-size: 28px;
    }
    margin-top: 3rem;
    margin-left: 20%;
    margin-right: 20%;
    color: white;
    font-size: 40px;

`; 

export const SubSubTitle = styled.h2`
    @media only screen and (max-width: 1000px) {
      font-size: 16px;
      margin-left: 2%;
      margin-right: 2%;
    }
    margin-top: 1rem;
    margin-left: 20%;
    margin-right: 20%;
    color: white;
    font-size: 20px;
`;

export const TextInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  font-size: 1.1rem;
  font-family: 'ForsvarsmaktenSans-Regular';
  inset: none;
  border-radius: 16px;
  color: white;
  border: 1px solid white;
  background-color: #717070;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #ddd;
  }
`;

export const UploadButton = styled.button`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  font-size: 1.1rem;
  font-family: 'ForsvarsmaktenSans-Regular';
  inset: none;
  border-radius: 16px;
  color: white;
  border: 1px solid white;
  background-color: #717070;
  transition: all 0.1s;

  cursor: pointer;

  &:focus {
    outline: none;
  }

`;

export const DividerSM = styled.div`
  margin-top: 0.75rem;
`;

export const DividerMD = styled.div`
  margin-top: 1.25rem;
`;

export const ContentContainer = styled.div`

  p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 1000px) {
    p {
      font-size: 1rem;
      margin-bottom: 0.8rem;
    }
  }
`;

export const FMLogo = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  margin: 1rem 7rem 0;

  img {
    max-height: 6.5rem;
  }

  @media only screen and (max-width: 1250px) {
    margin: 1rem 6rem 0;
    img {
      max-height: 6rem;
    }
  }
  @media only screen and (max-width: 1000px) {
    position: relative;
    margin: 0;
    text-align: left;
    img {
      max-height: 4rem;
    }
  }
`;