import styled from 'styled-components';

export const InformationTextContainer = styled.div`
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    color: white;
    display: flex;
    margin-left: 0rem;
    margin-right: 0rem;
    justify-content: space-between;

  }
    color: white;
    display: flex;
    margin-left: 0rem;
    margin-right: 0rem;
    justify-content: space-between;
`; 

export const InformationText = styled.h2`
    color: white;
`; 

export const StyledTimer = styled.h2`
    color: white;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;

  font-size: 3rem;
  font-family: 'ForsvarsmaktenSans-Stencil';
`; 

export const TimerStep2 = styled.h2`
  width: 100%;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    color: white;
    font-size: 150px;

`; 

export const Icon = styled.img`
  width: 50%;
  height: auto;
  
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation:landscape) {
    /* For landscape layouts only */
    width: 100%;
    height: auto;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    width: 125%;
    margin-left: -12.5%;
    height: auto;
  }
`; 

export const Minute1 = styled.div` 
    width: 6rem;
`; 

export const Minute2 = styled.div` 
    width: 6rem;
`; 

export const Second1 = styled.div`
    width: 6rem;
    
`; 

export const Second2 = styled.div`
    width: 6rem;
`;

export const Minute12 = styled.div` 
    width: 13px;
`; 

export const Minute22 = styled.div` 
    width: 13px;
`; 

export const Second12 = styled.div`
    width: 13px;
    
`; 

export const Second22 = styled.div`
    width: 13px;
`;

export const StyledList = styled.div`
    text-align: left;
    
    li {
        padding: 10px 0px;
    }
`;


/* Nya grejer */

export const BoardLayout = styled.div`

  display: grid; 
  grid-template-columns: 300px 1fr;
  grid-template-rows: * 1fr;
  gap: 0px 0px; 
  grid-template-areas: 
    "Title Title"
    "InformationPanel Game"; 
  overflow: hidden;

  border-bottom: 1px solid #b9b8b9;
`;

export const InformationPanel = styled.div`
  grid-area: InformationPanel;
  padding: 1rem;
  border: 1px solid #b9b8b9;
  border-bottom: 0;
  color: white;
`;

export const GameCanvas = styled.div`
  grid-area: Game;
  margin: 0 0 -4px 0;
  border-top: 1px solid #b9b8b9;
  border-right: 1px solid #b9b8b9;
`;

export const GameTitle = styled.div`
  grid-area: Title;
  text-align: center;
  margin: 0;
  padding: 0;

  h1 {
    -webkit-appearance: none;
    color: white;
    font-size: 55px;
    margin-top: 1rem;
  }
`;

export const ObstacleStack = styled.div`

  @media only screen and (max-width: 1000px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5vw;
    width: 100%;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const Obstacle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 16px;
  color: ${prop => prop.selected ? '#2BDB8C' : 'white'};

  span {
    @media only screen and (max-width: 1000px) {
      font-size: 1rem;
      margin-right: 2px:
    }
    font-family: 'ForsvarsmaktenSans-Regular';
    font-size: 1.9rem;
    width: 20%;
  }
  img {
    @media only screen and (max-width: 1000px) {
      width: 13vw;
    }
    width: 50%;
  }
`;

export const GameButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const GameButton = styled.button`
    vertical-align: top;
    color: white;
    min-width: 100px; 
    height: 70px;
    font-size: 20px;
    font-family: 'ForsvarsmaktenSans-Stencil';
    border: 2px solid #ffef00;
    border-radius: 10px;
    background-color: #717070;
    &:hover{
        cursor: pointer;
        background-color: #ffef00;
    }
`;

/* Mobil */

export const MobileTitle = styled.h1`
  -webkit-appearance: none;
  color: white;
  font-size: 22px;
  margin-bottom: 0rem;
`;

export const MobileBoardLayout = styled(BoardLayout)`
  grid-template-areas: 
    "Title Title"
    "Game Game"
    "InformationPanel InformationPanel";
`;

export const MobileInformationPanel = styled(InformationPanel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileGameCanvas = styled(GameCanvas)`
`;

export const MobileGameTitle = styled(GameTitle)`

`;


/* Slideshow */

export const SlideShowContainer = styled.div`
  position: relative;
  margin: 0 24.64%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  scroll-behavior: smooth;

  .innerContainer {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  .left-arrow {
    display: inline;
    left: 0;
    margin: 0 -6rem;
    position: absolute;
    img {
      opacity: ${prop => prop.ply ? 1 : 0.1};
      width: 8rem;
      transform: rotate(90deg);

      &:hover {
        cursor: ${prop => prop.ply ? 'pointer' : 'default'};
      }
    }
  }

  .right-arrow {
    display: inline;
    right: 0;
    margin: 0 -6rem;
    position: absolute;
    img {
      opacity: ${prop => !prop.ply ? 1 : 0.11};
      width: 8rem;
      transform: rotate(-90deg);

      &:hover {
        cursor: ${prop => !prop.ply ? 'pointer' : 'default'};
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    margin: 0 14.64%;
  }

`;

