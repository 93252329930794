import rotationPoints from './rotationPoints';

function getAngle(x, y) {
  let currentSmallest = 99999999999;
  let rotIndex = 0;
  for (let i = 0; i < rotationPoints.length; i++) {
    const a = Math.abs(rotationPoints[i][0] - x);
    const b = Math.abs(rotationPoints[i][1] - y);
    const dist = a*a + b*b;
    
    if (dist < currentSmallest) {
      currentSmallest = dist;
      rotIndex = i;
    }
  }

  if (rotationPoints.length > rotIndex+2) {
    return Math.atan2(
      rotationPoints[rotIndex+2][1] - rotationPoints[rotIndex][1],
      rotationPoints[rotIndex+2][0] - rotationPoints[rotIndex][0]
    ) * 180 / Math.PI + 90;
  } else {
    return Math.atan2(
      rotationPoints[rotIndex-2][1] - rotationPoints[rotIndex][1],
      rotationPoints[rotIndex-2][0] - rotationPoints[rotIndex][0]
    ) * 180 / Math.PI + 90;
  }
};

export {
  getAngle
};