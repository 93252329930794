import React, { useEffect, useState, useRef } from 'react';
import { ButtonStart, Container, DividerMD, DividerSM, MobileButtonStart, MobileContainer, Title } from './styles/common';
import { BoardLayout, GameButton, GameButtonContainer, GameCanvas, GameTitle, InformationPanel, MobileBoardLayout, MobileGameCanvas, MobileGameTitle, MobileInformationPanel, MobileTitle, Obstacle, ObstacleStack, StyledTimer } from './styles/game';
import ReactGA from 'react-ga4';
import { useWindowDimensions } from './WindowDimensions';

import { getAngle } from '../utils/mapMaths'

const Board = ({ cb, track, difficulty }) => {
  const { width } = useWindowDimensions();
  const screen = useRef(null);

  const [time, setTime] = useState(0);
  const [gameStarted, setGameStart] = useState(false);

  const [, setGuesses] = useState(0);
  const [, setPoints] = useState(0);

  const [obstacles, setObstacles] = useState([]);
  const [obstacleGuesses, setObstacleGuesses] = useState([]);

  const [visualObstacles, _setVisualObstacles] = useState([]);
  const visualObstaclesRef = useRef(visualObstacles);
  const setVisualObstacles = data => {
    visualObstaclesRef.current = data;
    _setVisualObstacles(data);
  };

  const [currentObstacle, _setCurrentObstacle]  = useState("vertical_red");
  const currentObstacleRef = useRef(currentObstacle);
  const setCurrentObstacle = data => {
    currentObstacleRef.current = data;
    _setCurrentObstacle(data);
  };

  const [gamePhase, _setGamePhase] = useState('START');
  const gamePhaseRef = useRef(gamePhase);
  const setGamePhase = data => {
    gamePhaseRef.current = data;
    _setGamePhase(data);
  };

  const isMobile = width <= 1000;

  // Setup sound cues
  let audio1 = new Audio("assets/sounds/beep1.mp3")
  let audio2 = new Audio("assets/sounds/horseneigh.mp3")
  audio1.volume = 0.3;
  audio2.volume = 0.3;

  useEffect(() => {
    setupGame();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time => {
        return timerFunction(time);
      })
    }, 1000);
    return () => clearTimeout(timer);
  }, [time]);

  const setupGame = () => {
    console.log('Initializing game...');
    if (screen === null) {
      console.error('Failed to initialize game');
      return;
    }
    window.addEventListener('resize', handleResize);
    loadCanvas();
  };

  const startGuessing = () => {
    setTime(difficulty.view_seconds);
    setGamePhase('GUESSING');
    drawObstacles(true);
  }

  const startGame = () => {
    loadCanvas();
    screen.current.addEventListener('click', handleClick, true);
    setGameStart(true);
    setGamePhase('PLAYING');

    ReactGA.event({
      category: 'Game',
      action: 'Started Game'
    });

    return difficulty.guess_seconds;
  }

  const endGame = () => {
    ReactGA.event({
      category: 'Game',
      action: 'Finished Game'
    });

    setPoints(points => {
      cb(points, obstacleGuesses);

      return points;
    });
  };

  const loadCanvas = () => {
    const canvas = screen.current;
    const ctx = canvas ? canvas.getContext('2d') : null;
    if (!ctx) { return; }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Game Board
    const image = new Image();

    image.src = 'assets/images/FM_HorseShow_Final.png';
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      if (gamePhaseRef.current === 'PLAYING') {
        drawPlayerObstacles();
      }
    };
  };

  const drawObstacles = (updateData = true) => {
    // Update Coordinates
    setObstacles(obstacles => {
      obstacles.splice(0, obstacles.length);

      formatTrack(track.attributes).forEach(e => createObstacle(e));
      setGuesses(obstacles.length);

      return obstacles;
    });
  }

  const drawPlayerObstacles = () => {
    obstacleGuesses.forEach(e => createPlayerObstacle(e));
  }

  const formatTrack = (track) => {
    let res = [];
    for (let i = 0; i < track.TrackPoint.length; i++) {
      const e = track.TrackPoint[i];
      res.push({type: e.type, position: [parseFloat(e.x), parseFloat(e.y)]})
    }
    return res;
  }

  const handleResize = (e) => {
    if (e.currentTarget.innerWidth !== width) {
        loadCanvas();
        if (gamePhaseRef.current === 'GUESSING') {
          drawObstacles();
        }
    }
  };
  
  const handleClick = (e) => {
    //Make Guess
    handleGuess(e.offsetX, e.offsetY);
  };

  const handleGuess = (x, y) => {
    setGuesses(guesses => {
      if (guesses > 0) {
        // Remove visual obstacle
        const index = visualObstaclesRef.current.findIndex((e) => e.type === currentObstacleRef.current);
        visualObstaclesRef.current.splice(index, 1);

        // Get Data
        let data = calculateData(getBoardX(x), getBoardY(y));
        setPoints(points => points + data.points);

        obstacleGuesses.push({
          type: currentObstacleRef.current,
          position: [parseFloat(getBoardX(x)), parseFloat(getBoardY(y))],
          points: data.points,
          deletedObstacle: data.deletedObstacle
        });
        
        // Create obstacle
        const image = new Image();
        image.src = `assets/images/obstacles/${currentObstacleRef.current}.png`;
        image.onload = () => {
          const width = screen.current !== null ? screen.current.width / 15 : "100px";
          drawRotatedImage(x, y, width, image, data.angle);
        };
        
        // Update Selected Obstacle
        if (visualObstaclesRef.current !== []) {
          setCurrentObstacle(visualObstaclesRef.current[0] ? visualObstaclesRef.current[0].type : "");
        }

        ReactGA.event({
          category: 'Game',
          action: 'Created Guess'
        });

        if (guesses - 1 <= 0) {
          //endGame();
          return 0;
        }
        return guesses - 1;
      } else {
        return 0;
      }
    });
  };

  const undoGuess = () => {
    // Remove last element in obstacleGuesses.
    let remObs = obstacleGuesses.pop();
    if (remObs === undefined) { return; }

    // Remove points from that obstacle
    if (remObs.points > 0) {
      setPoints(points => points - remObs.points);
      if (remObs.deletedObstacle !== undefined) {
        obstacles.push(remObs.deletedObstacle);
        visualObstacles.push({image: remObs.deletedObstacle.image, type: remObs.deletedObstacle.type});
      }
    } else {
      const image = new Image();
      image.src = `assets/images/obstacles/${remObs.type}.png`;
      visualObstacles.push({image, type: remObs.type});
    }

    setGuesses(guesses => guesses + 1);
    loadCanvas();

    // Update Selected Obstacle
    if (visualObstaclesRef.current !== []) {
      setCurrentObstacle(visualObstaclesRef.current[0] ? visualObstaclesRef.current[0].type : "");
    }
  }

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const calculateData = (x, y) => {
    let distance = 99999999999;
    let obstacleIndex = 0;
    for (let i = 0; i < obstacles.length; i++) {
      const a = Math.abs(obstacles[i].x - x);
      const b = Math.abs(obstacles[i].y - y);
      const dist = a*a + b*b;

      if (dist < distance) {
        distance = dist;
        obstacleIndex = i;
      }
    }

    //let points = Math.round(10 * (1-distance*8));
    let points = clamp(Math.round(10 * (1 - Math.sqrt(distance) * 3)), 0, 10);
    let deletedObstacle = undefined;

    if (points > 0 && currentObstacleRef.current === obstacles[obstacleIndex].type) {
      deletedObstacle = obstacles.splice(obstacleIndex, 1)[0];
    } else {
      points = 0;
    }

    return {
      angle: getAngle(x, y),
      points: points,
      removeIndex: (points > 0 ? obstacleIndex : undefined),
      deletedObstacle: deletedObstacle,
    };
  };

  const drawRotatedImage = (x, y, width, image, degrees) => {
    if (screen.current == null) { return; }
    const canvas = screen.current;
    const ctx = canvas.getContext('2d');

    const ratio = image.height / image.width;

    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(degrees * Math.PI/180);
    ctx.drawImage(image, -width / 2, -width*ratio / 2, width, width*ratio);
    ctx.restore();
  };

  const createObstacle = (obstacle) => {
    
    const image = new Image();
    image.src = `assets/images/obstacles/${obstacle.type}.png`;
    image.onload = () => {
      const xPos = obstacle.position[0] * screen.current.width;
      const yPos = obstacle.position[1] * screen.current.height;
      const width = screen.current.width / 15;

      drawRotatedImage(xPos, yPos, width, image, getAngle(getBoardX(xPos), getBoardY(yPos)));
    };

    obstacles.push({x: obstacle.position[0], y: obstacle.position[1], image: image, type: obstacle.type });
    visualObstacles.push({image, type: obstacle.type});
  };

  const createPlayerObstacle = (obstacle) => {
    const image = new Image();
    image.src = `assets/images/obstacles/${obstacle.type}.png`;
    image.onload = () => {
      const xPos = obstacle.position[0] * screen.current.width;
      const yPos = obstacle.position[1] * screen.current.height;
      const width = screen.current.width / 15;

      drawRotatedImage(xPos, yPos, width, image, getAngle(getBoardX(xPos), getBoardY(yPos)));
    };
  }

  const getBoardX = (x) => x / screen.current.width;
  const getBoardY = (y) => y / screen.current.height;

  const timerString = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes > 9 ? minutes : "0"+minutes}:${seconds > 9 ? seconds : "0"+seconds}`;
  };

  const timerFunction = (time) => {
    if (gamePhaseRef.current === 'START') {
      return time
    }
    if (time <= 0) {
      if (!gameStarted) {
        return startGame();
      }
      
      endGame();
      return 0;
    }
    if (time <= 1 && !gameStarted) {
      audio2.play();
    }

    if (time <= 5) {
      audio1.play();
    }
    return time - 1;
  }

  const createObstacleList = () => {
    const data = {};
    visualObstacles.forEach((x) => {
      data[x.type] = (data[x.type] || 0) + 1;
    });

    return Object.keys(data).map(key =>
      <Obstacle selected={currentObstacleRef.current === key}>
        <span>{data[key]}x</span>
        <img src={`assets/images/obstacles/${key}.png`} alt={key} onClick={handleObstacleClick.bind(this, key)}></img>
      </Obstacle>
    );
  };

  const handleObstacleClick = (type) => {
    setCurrentObstacle(type);
  };

  if (isMobile) {
    return (<div>
      <MobileBoardLayout>
        <MobileGameTitle>
          <MobileTitle>
            Har du minne som en häst?
          </MobileTitle>
        </MobileGameTitle>
        <MobileGameCanvas>
          <canvas ref={screen} width={width} height={width * 1731/3431}></canvas>
        </MobileGameCanvas>
        <MobileInformationPanel>
          {gameStarted 
            ? <>
                <p>Välj hinder genom att klicka på det, därefter klicka på banan för att placera ut hindret.</p>
                <DividerMD></DividerMD>
                <ObstacleStack>
                  {createObstacleList()}
                </ObstacleStack>
                <GameButtonContainer>
                  <GameButton onClick={endGame.bind(this)}>Fortsätt</GameButton>
                  <GameButton onClick={undoGuess.bind(this)}>Ångra</GameButton>
                </GameButtonContainer>
              </>
            : <>
                <p>Memorera alla hinder på banan, som kommer visas när du klickar på ”Starta”.</p>
                <DividerSM />
                <p>När tiden är slut kommer olika hinder visas i den här rutan. Placera ut dem genom att klicka på ett hinder och klicka sedan på banan där du vill placera det.</p>
                <DividerSM />
              </>
            }
          {gamePhase === 'START'
            ? <MobileButtonStart onClick={startGuessing.bind(this)}>Starta</MobileButtonStart>
            : <></>
          }
        </MobileInformationPanel>
      </MobileBoardLayout>
      <MobileContainer>
        <StyledTimer>{timerString()}</StyledTimer>
      </MobileContainer>
    </div>);
  } else {
    return (
      <div>
        <BoardLayout>
          <GameTitle>
            <Title>
              Har du minne som en häst?
            </Title>
          </GameTitle>
          <InformationPanel>
            {gameStarted 
              ? <>
                  <p>Välj hinder genom att klicka på det, därefter klicka på banan för att placera ut hindret.</p>
                  <DividerSM />
                  <DividerMD></DividerMD>
                  <ObstacleStack>
                    {createObstacleList()}
                  </ObstacleStack>
                  <GameButtonContainer>
                    <GameButton onClick={endGame.bind(this)}>Fortsätt</GameButton>
                    <GameButton onClick={undoGuess.bind(this)}>Ångra</GameButton>
                  </GameButtonContainer>
                </>
              : <>
                  <p>Memorera alla hinder på banan till höger, som kommer visas när du klickar på ”Starta”.</p>
                  <DividerSM />
                  <p>När tiden är slut kommer olika hinder visas i den här rutan. Placera ut dem genom att klicka på ett hinder och klicka sedan på banan där du vill placera det.</p>
                  <DividerSM />
                </>
            }
            <DividerMD />
            {gamePhase === 'START'
              ? <ButtonStart onClick={startGuessing.bind(this)}>Starta</ButtonStart>
              : <></>
            }
          </InformationPanel>
          <GameCanvas>
            <canvas ref={screen} width={(width - 300)} height={(width - 300) * 1731/3431}></canvas>
          </GameCanvas>
        </BoardLayout>
        <Container>
          <StyledTimer>{timerString()}</StyledTimer>
        </Container>
      </div>
    );
  }
};

export default Board;
