import React, { useEffect, useState, useRef } from 'react';
import { Api } from '../services/ApiService';
import Board from './Board';
import { ButtonContainer, ButtonCTA, ButtonStart, Container, ContentContainer, DividerMD, DividerSM, FMLogo, Select, SingleButtonContainer, SubSubTitle, Title } from './styles/common';
import { Icon, SlideShowContainer, StyledList } from './styles/game';
import ReactMarkdown from 'react-markdown';
import ReactGA from 'react-ga4';
import { useWindowDimensions } from './WindowDimensions';
import { getAngle } from '../utils/mapMaths'
import qs from "qs";

const Game = () => {
  const { width } = useWindowDimensions();
  const [difficulty, setDifficulty] = useState(1);
  const [gameState, setGameState] = useState("START");

  const [difficulties, setDifficulties] = useState([]);
  const [event, setEvent] = useState({});
  const [tracks, setTracks] = useState([]);
  const [currentTrack, setCurrentTrack] = useState({});

  const [seconds, setSeconds] = useState([30, 60]);

  const isMobile = width <= 1000;

  const screen = useRef(null);
  const playerScreen = useRef(null);
  const [points, setPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);
  const [guesses, setGuesses] = useState(0);

  const [showPlayerObstacles, setShowPlayerObstacles] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/'});
    getEvent()
    getDifficulty();
  }, []);

  useEffect(() => {
    if (difficulties[difficulty - 1] !== undefined) {
      setSeconds([difficulties[difficulty - 1].view_seconds, difficulties[difficulty - 1].guess_seconds]);
    }
  }, [difficulty]);

  useEffect(() => {
    if (gameState === "END") {
      window.addEventListener('resize', handleResize);
      loadCanvas();
    }
  }, [gameState]);

  useEffect(() => {
    if (currentTrack.id) 
    {
      setGameState("PLAYING");
    }
  }, [currentTrack]);

  const getDifficulty = () => {
    const query = qs.stringify({
      populate: {
        difficulties: {
          populate: ['tracks'],
        }
      }
    }, {
      encodeValuesOnly: true,
    })
    Api.get(`/api/difficulty?${query}`).then(res => {
      setDifficulties(res.data.data.attributes.difficulties);
      setDifficulty(1);
    });
  }

  const getEvent = async (id = undefined) => {
    const t = id ? `/${id}` : "";
    Api.get(`/api/events${t}?populate=%2A`).then(async res => {
      if (res.data.data.length === 0) {
        setGameState("NO_EVENT");
      } else {
        setEvent(res.data.data[0]);
        ReactGA.event({
          category: 'Event',
          action: `Loaded Event ${res.data.data[0].attributes.Header}.`,
          value: res.data.data[0].id
        });

        const trackArr = res.data.data[0].attributes.tracks.data;
        trackArr.forEach(({id}) => getTrack(id));
      }
    })
  }

  const getTrack = async (id) => {
    await Api.get(`/api/tracks/${id}?populate[0]=TrackPoint`).then(res => {
      setTracks(tracks => [...tracks, res.data.data])
      //tracks.push(res.data.data.attributes);
    })
  }

  const postAttempt = (score, track, event) => {
    Api.post('/api/attempts', {
      data: {
        score,
        track,
        event
      }
    });
  }

  const initBoard = () => {
    // Create an Array with numbers that represent what track ids for this difficulty.
    const difficultyTracks = difficulties[difficulty-1].tracks.data.map(e => e.id);

    // Filter tracks to only play those whose difficulty is set.
    const tracksFiltered = tracks.filter(track => difficultyTracks.includes(track.id));

    // Get a random track from the filtered list.
    const randomTrack = tracksFiltered[Math.floor(Math.random()*tracksFiltered.length)];

    //console.log(difficulties[difficulty-1], difficultyTracks, tracks, tracksFiltered, randomTrack);
    setCurrentTrack(randomTrack);
    
  }

  // End Game Callback
  const boardCallback = (score, playerGuesses) => {
    setPoints(score);
    setMaxPoints(currentTrack.attributes.TrackPoint.length * 10);
    setGuesses(playerGuesses);
    setGameState("END");

    //postAttempt(score, currentTrack.id, event.id);
  }

  const loadCanvas = () => {
    let screenCtx = screen.current.getContext('2d');
    let plyScreenCtx = playerScreen.current.getContext('2d');

    screenCtx.clearRect(0, 0, screen.current.width, screen.current.height);
    plyScreenCtx.clearRect(0, 0, playerScreen.current.width, playerScreen.current.height);
    
    // Game Board
    const image = new Image();
    image.src = 'assets/images/FM_HorseShow_Final.png';
    image.onload = () => {
      screenCtx.drawImage(image, 0, 0, screen.current.width, screen.current.height);
      plyScreenCtx.drawImage(image, 0, 0, playerScreen.current.width, playerScreen.current.height);
      
      formatTrack(currentTrack.attributes).forEach(e => drawObstacle(e, screenCtx, screen.current.width, screen.current.height));
      guesses.forEach(e => drawObstacle(e, plyScreenCtx, playerScreen.current.width, playerScreen.current.height));
    };


  };

  const drawObstacle = (obstacle, ctx, width, height, isPlayer = false) => {
    const image = new Image();
    image.src = `assets/images/obstacles/${obstacle.type}${isPlayer ? "_ply" : ""}.png`;
    image.onload = () => {
      const xPos = obstacle.position[0] * width;
      const yPos = obstacle.position[1] * height;

      drawRotatedImage(xPos, yPos, width / 15, image, ctx, getAngle(getBoardX(xPos), getBoardY(yPos)));
    };
  }

  const drawRotatedImage = (x, y, width, image, ctx, degrees) => {
    const ratio = image.height / image.width;

    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(degrees * Math.PI/180);
    ctx.drawImage(image, -width / 2, -width*ratio / 2, width, width*ratio);
    ctx.restore();
  };

  const formatTrack = (track) => {
    let res = [];
    for (let i = 0; i < track.TrackPoint.length; i++) {
      const e = track.TrackPoint[i];
      res.push({type: e.type, position: [parseFloat(e.x), parseFloat(e.y)]})
    }
    return res;
  }

  const handleResize = (e) => {
    if (e.currentTarget.innerWidth !== width) {
        loadCanvas();
    }
  };

  const getBoardX = (x) => x / screen.current.width;
  const getBoardY = (y) => y / screen.current.height;

  const instructionText = isMobile ? "under banan" : "till vänster om banan"

  const mapScroll = (left) => {
    if (left !== showPlayerObstacles) { return; }
    if (!showPlayerObstacles) {
      playerScreen.current.scrollIntoView();
      setShowPlayerObstacles(true);
    } else {
      screen.current.scrollIntoView();
      setShowPlayerObstacles(false);
    }

    setMapToggle(t => !t);
  }


  const stateState = (
    <Container>
      <FMLogo>
        <a href='https://www.forsvarsmakten.se/'>
          <img src='assets/images/FM_logo_top.png' alt='' />
        </a>
      </FMLogo>
      <div style={{marginTop: isMobile ? "0" : "3rem"}}>
        <Icon src={'assets/images/HorseHead.png'}></Icon>
        {isMobile
          ? <>
              <Title>HAR DU MINNE</Title>
              <Title>SOM EN HÄST?</Title>
            </>
          : <Title>HAR DU MINNE SOM EN HÄST?</Title>
        }
        
        <SubSubTitle>
          <StyledList>
            <ol>
              <li>Du kommer under {seconds[0]} sekunder få se en bild på en hoppbana där du ska memorera hindrens placering.</li>
              <li>Du har sedan {seconds[1]} sekunder på dig att placera ut alla hinder.</li>
              <li>Spelet tar slut när din tid är slut eller om du klickar på fortsätt.</li>
            </ol>
          </StyledList>
        </SubSubTitle>
        <ButtonContainer>
          <Select
            name="difficulty"
            id=""
            value={difficulty}
            onChange={e => setDifficulty(e.target.value)}
          >
            {difficulties.map(e => <option value={e.level}>Nivå {e.level}</option>)}
          </Select>

          <ButtonStart onClick={() => initBoard()}>Starta</ButtonStart>
        </ButtonContainer>
      </div>
    </Container>
  );

  const playState = (
      <Board cb={boardCallback} track={currentTrack} difficulty={difficulties[difficulty-1]} />
  );

  const [mapToggle, setMapToggle] = useState(false);

  const endState = (
    <Container>
      <Icon src={'assets/images/HorseHead.png'}></Icon>
      <Title>Grattis, du fick {points} av {maxPoints} poäng!</Title>
      <DividerMD />
      <ContentContainer>
        <ReactMarkdown children={event.attributes ? event.attributes.content : ""}></ReactMarkdown>
      </ContentContainer>
      <SingleButtonContainer>
        <a href={event.attributes ? event.attributes.button_link : ""}>
          <ButtonCTA onClick={ReactGA.send({ hitType: 'pageview', page: '/FMRegister'})}>Skapa Profil</ButtonCTA>
        </a>
      </SingleButtonContainer>
      <DividerSM />
      {/*<p><em>Tävlingen pågår till och med 18 mars. Vid eventuell vinst står vinnaren själv för boende och resa.</em></p>*/}
      <DividerMD />
      
      <SlideShowContainer ply={showPlayerObstacles}>
        <div className='left-arrow' onClick={mapScroll.bind(this, true)}>
          <img src={'assets/images/select_yellow.png'} alt="Left Arrow" />
        </div>
        <div className='innerContainer'>
          <div>
            <SubSubTitle>Facit</SubSubTitle>
            <canvas ref={screen} width={isMobile ? width / 1.5 : width / 2.5} height={(isMobile ? width / 1.5 : width / 2.5) * 1731/3431}></canvas>
          </div>
          <div>
            <SubSubTitle>Dina svar</SubSubTitle>
            <canvas ref={playerScreen} width={isMobile ? width / 1.5 : width / 2.5} height={(isMobile ? width / 1.5 : width / 2.5) * 1731/3431}></canvas>
          </div>
        </div>
        <div className='right-arrow' onClick={mapScroll.bind(this, false)}>
          <img src={'assets/images/select_yellow.png'} alt="Left Arrow" />
        </div>
      </SlideShowContainer>

    </Container>
  );

  const noEvent = (
    <Container>
      <FMLogo>
        <a href='https://www.forsvarsmakten.se/'>
          <img src='assets/images/FM_logo_top.png' alt='' />
        </a>
      </FMLogo>
      <Icon src={'assets/images/HorseHead.png'}></Icon>
      <Title>Hej vad roligt att du hittade hit!</Title>
      <DividerMD />
      <SubSubTitle>Just nu har vi inget event, titta gärna förbi någon gång i framtiden</SubSubTitle>
    </Container>
  )

  switch (gameState) {
    case "START":
      return stateState;
    case "PLAYING":
      ReactGA.send({ hitType: 'pageview', page: '/playing'});
      return playState;
    case "END":
      ReactGA.send({ hitType: 'pageview', page: '/end'});
      return endState;
    case "NO_EVENT":
      ReactGA.send({ hitType: 'pageview', page: '/noevent'});
      return noEvent;
    default:
      ReactGA.exception('Wrong pageview, something went wrong here');
      return <Title>Oj, något gick snett. Ladda om sidan :)</Title>;
  }
};

export default Game;
