import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'ForsvarsmaktenSans-Stencil';
  src: url("assets/fonts/ForsvarsmaktenSans-Stencil.woff") format('woff');
}
@font-face {
  font-family: 'ForsvarsmaktenSans-Regular';
  src: url("assets/fonts/ForsvarsmaktenSans-Regular.woff") format('woff');
}
@font-face {
  font-family: 'ForsvarsmaktenSans-Light';
  src: url("assets/fonts/ForsvarsmaktenSans-Light.woff") format('woff');
}
h1{
  font-family: 'ForsvarsmaktenSans-Stencil';
  font-weight: normal;
}
h2{
  font-family: 'ForsvarsmaktenSans-Regular';
  font-weight: normal;
}
p {
  font-family: 'ForsvarsmaktenSans-Regular';
  font-weight: normal;
}
* {
  box-sizing: border-box;
  margin: 0;
  
}
body{
  background-color: #717070;
  height: 100%;
}

#root {
  height: 100vh;
}
`

ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  document.getElementById('root')
);

