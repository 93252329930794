import React, { useEffect, useState, useRef } from "react";
import { Container, InputContainer, DividerMD, TextInput, Title, UploadButton } from './styles/common';
import { BoardLayout, GameCanvas, GameTitle, Icon, InformationPanel, Obstacle, ObstacleStack } from './styles/game';
import { getAngle } from "../utils/mapMaths";
import { useCookies } from "react-cookie";
import { Api } from "../services/ApiService";

// Copy of Board component except game elements.
const Creator = () => {
  const { width } = useWindowDimensions();
  const screen = useRef(null);

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  
  const [obstacles, setObstacles] = useState([]);
  const [mapName, setMapName] = useState("");

  const [currentObstacle, _setCurrentObstacle]  = useState("vertical_red");
  const currentObstacleRef = useRef(currentObstacle);
  const setCurrentObstacle = data => {
    currentObstacleRef.current = data;
    _setCurrentObstacle(data);
  };

  const [cookies, setCookie] = useCookies(['token']);


  useEffect(() => {
    if (cookies.token) {
      setupCreator();
    }
  }, []);

  const signIn = () => {
    Api.post('/api/auth/local', {
      identifier,
      password
    }).then(res => {
      setCookie('token', res.data.jwt, { path: '/'});
      setupCreator();
    });
  }

  const uploadMap = () => {
    Api.post('/api/tracks', {
      data: {
        "Name": mapName,
        "TrackPoint": obstacles.map(e => {
          return {
            type: e.type,
            x: e.x.toString(),
            y: e.y.toString()
          }
        })
      }
    }, {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      }
    }).then(res => window.location.reload(false));
  }

  const setupCreator = () => {
    console.log('Initializing creator...');
    if (screen === null) {
      console.error('Failed to initialize creator');
      return;
    }
    window.addEventListener('resize', handleResize);
    loadCanvas();
    screen.current.addEventListener('click', handleClick, true);
  };

  const loadCanvas = () => {
    const canvas = screen.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Game Board
    const image = new Image();
    image.src = 'assets/images/FM_HorseShow_Final.png';
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height); 
      obstacles.forEach(e => createObstacle(e.x, e.y, e.type));

    };
  };

  const handleResize = (e) => {
    if (e.currentTarget.innerWidth !== width) {
      loadCanvas();
    }
  };

  const handleClick = (e) => {
    //Debug Stuff

    const inRange = obstacleInRange(getBoardX(e.offsetX), getBoardY(e.offsetY));
    if (inRange[0]) {
      removeObstacle(inRange[1]);
    } else {
      createObstacle(getBoardX(e.offsetX), getBoardY(e.offsetY));
    }

  };

  const obstacleInRange = (x, y) => {
    let distance = 99999999999;
    let obstacleIndex = 0;
    for (let i = 0; i < obstacles.length; i++) {
      const a = Math.abs(obstacles[i].x - x);
      const b = Math.abs(obstacles[i].y - y);
      const dist = Math.sqrt(a*a + b*b);

      if (dist < distance) {
        distance = dist;
        obstacleIndex = i;
      }
    }

    if (distance < 0.018) {
      return [true, obstacleIndex]
    }
    return [false, 0];
  };

  const createObstacle = (x, y, type = undefined) => {
    const image = new Image();
    image.src = `assets/images/obstacles/${type ?? currentObstacleRef.current}.png`;
    image.onload = () => {
      const xPos = x * screen.current.width;
      const yPos = y * screen.current.height;
      const width = screen.current.width / 15;

      drawRotatedImage(xPos, yPos, width, image, getAngle(getBoardX(xPos), getBoardY(yPos)));
    };
    if (!type) {
      obstacles.push({x, y, image: image, type: type ?? currentObstacleRef.current });
    }
  };

  const removeObstacle = (index) => {
    setObstacles(obstacles => {
      obstacles.splice(index, 1);
      return obstacles;
    });
    
    loadCanvas();
  };

  const drawRotatedImage = (x, y, width, image, degrees) => {
    if (screen.current == null) { return; }
    const canvas = screen.current;
    const ctx = canvas.getContext('2d');

    const ratio = image.height / image.width;

    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(degrees * Math.PI/180);
    ctx.drawImage(image, -width / 2, -width*ratio / 2, width, width*ratio);
    ctx.restore();
  };

  const getBoardX = (x) => x / screen.current.width;
  const getBoardY = (y) => y / screen.current.height;

  const handleObstacleClick = (type) => {
    setCurrentObstacle(type);
  };

  if (cookies.token) {
    return (
      <div>
        <BoardLayout>
          <GameTitle>
            <Title>Skapa bana</Title>
          </GameTitle>
          <InformationPanel>
            <h2>Välj ett hinder</h2>
            <DividerMD />
            <ObstacleStack>
              <Obstacle>
                <img src={`assets/images/obstacles/vertical_red.png`} alt="red" onClick={handleObstacleClick.bind(this, "vertical_red")}></img>
              </Obstacle>
              <Obstacle>
                <img src={`assets/images/obstacles/vertical_yellow.png`} alt="yellow" onClick={handleObstacleClick.bind(this, "vertical_yellow")}></img>
              </Obstacle>
              <Obstacle>
                <img src={`assets/images/obstacles/vertical_blue.png`} alt="blue" onClick={handleObstacleClick.bind(this, "vertical_blue")}></img>
              </Obstacle>
              <Obstacle>
                <img src={`assets/images/obstacles/water.png`} alt="water" onClick={handleObstacleClick.bind(this, "water")}></img>
              </Obstacle>
              <Obstacle>
                <img src={`assets/images/obstacles/wall.png`} alt="wall" onClick={handleObstacleClick.bind(this, "wall")}></img>
              </Obstacle>
            </ObstacleStack>
            <DividerMD />
            
            <TextInput
              type="text"
              name="map"
              placeholder="Namn på Bana"
              onChange={ (e) => setMapName(e.target.value)}
            />
            <UploadButton onClick={uploadMap.bind(this)}>
              Spara bana
            </UploadButton>
          </InformationPanel>
          <GameCanvas>
            <canvas ref={screen} width={(width - 300)} height={(width - 300) * 1731/3431}></canvas>
          </GameCanvas>
        </BoardLayout>
      </div>
    );
  } else {
    return (
      <div>
        <Container>
          <Icon src={'assets/images/HorseHead.png'}></Icon>
          <Title>Logga in</Title>
          <DividerMD />
          <InputContainer>
            <TextInput 
              placeholder="Användarnamn"
              type="text"
              name="username"
              onChange={ (e) => setIdentifier(e.target.value)}
            />
            <TextInput
              placeholder="Lösenord"
              type="password"
              name="password"
              onChange={ (e) => setPassword(e.target.value)}
            />
            <UploadButton onClick={signIn.bind(this)}>Logga in</UploadButton>
          </InputContainer>
        </Container>
      </div>
    );
  }

  
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default Creator;