const rotationPoints = [
  [0.3394736842105263, 0.43478260869565216],
  [0.31666666666666665, 0.4834782608695652],
  [0.3026315789473684, 0.5095652173913043],
  [0.29210526315789476, 0.5373913043478261],
  [0.2719298245614035, 0.5739130434782609],
  [0.256140350877193, 0.6034782608695652],
  [0.22894736842105262, 0.6382608695652174],
  [0.19824561403508772, 0.6556521739130434],
  [0.17280701754385966, 0.6573913043478261],
  [0.14385964912280702, 0.6417391304347826],
  [0.12543859649122807, 0.6260869565217392],
  [0.0912280701754386, 0.5739130434782609],
  [0.07456140350877193, 0.528695652173913],
  [0.062280701754385964, 0.48],
  [0.05701754385964912, 0.4365217391304348],
  [0.05526315789473684, 0.3791304347826087],
  [0.05789473684210526, 0.32],
  [0.06666666666666667, 0.2643478260869565],
  [0.07894736842105263, 0.22086956521739132],
  [0.09473684210526316, 0.17391304347826086],
  [0.11403508771929824, 0.1426086956521739],
  [0.14473684210526316, 0.11478260869565217],
  [0.17280701754385966, 0.09913043478260869],
  [0.2131578947368421, 0.10260869565217391],
  [0.24473684210526317, 0.09739130434782609],
  [0.26842105263157895, 0.09739130434782609],
  [0.2912280701754386, 0.09913043478260869],
  [0.31491228070175437, 0.09739130434782609],
  [0.33771929824561403, 0.09739130434782609],
  [0.36666666666666664, 0.09739130434782609],
  [0.3956140350877193, 0.09739130434782609],
  [0.4245614035087719, 0.09739130434782609],
  [0.45526315789473687, 0.09913043478260869],
  [0.4824561403508772, 0.09565217391304348],
  [0.5166666666666667, 0.09565217391304348],
  [0.5456140350877193, 0.09739130434782609],
  [0.5780701754385965, 0.09739130434782609],
  [0.6175438596491228, 0.09913043478260869],
  [0.656140350877193, 0.09565217391304348],
  [0.6885964912280702, 0.09739130434782609],
  [0.7210526315789474, 0.09913043478260869],
  [0.7508771929824561, 0.10434782608695652],
  [0.7842105263157895, 0.13391304347826086],
  [0.8070175438596491, 0.16521739130434782],
  [0.8228070175438597, 0.19826086956521738],
  [0.8403508771929824, 0.25217391304347825],
  [0.8508771929824561, 0.32521739130434785],
  [0.8464912280701754, 0.4226086956521739],
  [0.8271929824561404, 0.5321739130434783],
  [0.8026315789473685, 0.5982608695652174],
  [0.7412280701754386, 0.6521739130434783],
  [0.6991228070175438, 0.6469565217391304],
  [0.6622807017543859, 0.6191304347826087],
  [0.6385964912280702, 0.5808695652173913],
  [0.6157894736842106, 0.5356521739130434],
  [0.5877192982456141, 0.47130434782608693],
  [0.5578947368421052, 0.40869565217391307],
  [0.531578947368421, 0.34782608695652173],
  [0.5008771929824561, 0.2852173913043478],
  [0.4710526315789474, 0.23652173913043478],
  [0.42543859649122806, 0.20695652173913043],
  [0.3850877192982456, 0.20521739130434782],
  [0.3350877192982456, 0.20695652173913043],
  [0.30526315789473685, 0.21043478260869566],
  [0.2824561403508772, 0.20869565217391303],
  [0.2517543859649123, 0.20695652173913043],
  [0.22543859649122808, 0.21043478260869566],
  [0.19736842105263158, 0.21043478260869566],
  [0.17192982456140352, 0.22782608695652173],
  [0.15, 0.2539130434782609],
  [0.13157894736842105, 0.28695652173913044],
  [0.11578947368421053, 0.32869565217391306],
  [0.10350877192982456, 0.3826086956521739],
  [0.09912280701754386, 0.4330434782608696],
  [0.09649122807017543, 0.5182608695652174],
  [0.1, 0.5652173913043478],
  [0.10964912280701754, 0.6104347826086957],
  [0.12368421052631579, 0.6521739130434783],
  [0.14298245614035088, 0.6939130434782609],
  [0.16578947368421051, 0.7304347826086957],
  [0.1956140350877193, 0.7513043478260869],
  [0.22631578947368422, 0.7634782608695653],
  [0.256140350877193, 0.7634782608695653],
  [0.2868421052631579, 0.7634782608695653],
  [0.3350877192982456, 0.7634782608695653],
  [0.3736842105263158, 0.7634782608695653],
  [0.393859649122807, 0.7634782608695653],
  [0.4070175438596491, 0.7634782608695653],
  [0.4307017543859649, 0.7565217391304347],
  [0.45614035087719296, 0.7286956521739131],
  [0.48333333333333334, 0.7008695652173913],
  [0.5157894736842106, 0.6295652173913043],
  [0.5368421052631579, 0.5843478260869566],
  [0.5570175438596491, 0.542608695652174],
  [0.5710526315789474, 0.5113043478260869],
  [0.5877192982456141, 0.4747826086956522],
  [0.6105263157894737, 0.40695652173913044],
  [0.6280701754385964, 0.37043478260869567],
  [0.6517543859649123, 0.3356521739130435],
  [0.6763157894736842, 0.27652173913043476],
  [0.6947368421052632, 0.2417391304347826],
  [0.7140350877192982, 0.1826086956521739],
  [0.7429824561403509, 0.13043478260869565],
  [0.7763157894736842, 0.10260869565217391],
  [0.8070175438596491, 0.09565217391304348],
  [0.8350877192982457, 0.09913043478260869],
  [0.8587719298245614, 0.11478260869565217],
  [0.8894736842105263, 0.15304347826086956],
  [0.9035087719298246, 0.18782608695652173],
  [0.9175438596491228, 0.23304347826086957],
  [0.9263157894736842, 0.28347826086956524],
  [0.9307017543859649, 0.33043478260869563],
  [0.9324561403508772, 0.37217391304347824],
  [0.9350877192982456, 0.4295652173913043],
  [0.9350877192982456, 0.47304347826086957],
  [0.9333333333333333, 0.5269565217391304],
  [0.9315789473684211, 0.6069565217391304],
  [0.9236842105263158, 0.662608695652174],
  [0.9140350877192982, 0.7060869565217391],
  [0.9017543859649123, 0.7513043478260869],
  [0.8824561403508772, 0.7930434782608695],
  [0.8640350877192983, 0.8173913043478261],
  [0.8359649122807018, 0.8347826086956521],
  [0.8061403508771929, 0.84],
  [0.7719298245614035, 0.8417391304347827],
  [0.743859649122807, 0.8417391304347827],
  [0.7087719298245614, 0.8469565217391304],
  [0.6807017543859649, 0.8469565217391304],
  [0.6508771929824562, 0.8486956521739131],
  [0.6228070175438597, 0.8486956521739131],
  [0.5903508771929824, 0.8486956521739131],
  [0.5596491228070175, 0.8504347826086956],
  [0.5210526315789473, 0.8486956521739131],
  [0.48771929824561405, 0.8469565217391304],
  [0.4517543859649123, 0.8434782608695652],
  [0.4131578947368421, 0.8382608695652174],
  [0.37280701754385964, 0.8260869565217391],
  [0.33157894736842103, 0.7878260869565218],
  [0.306140350877193, 0.7443478260869565],
  [0.27807017543859647, 0.6939130434782609],
  [0.2543859649122807, 0.6417391304347826],
  [0.2307017543859649, 0.5930434782608696],
  [0.2131578947368421, 0.5547826086956522],
  [0.20350877192982456, 0.5304347826086957],
  [0.19298245614035087, 0.5008695652173913],  
];

export default rotationPoints;